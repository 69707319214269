import React, { useState } from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import { Helmet } from 'react-helmet'

export default function Saool() {

  return (
    <div>
        <div className="container">
        <Helmet>
            <title>ما هو سؤول</title>
            <meta name="description" content="ما هو سؤول" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://saool.io/" />
            <meta property="og:title" content="سياسة الخصوصية - سؤول" />
            <meta property="og:description" content="ما هو سؤول" />
            <meta property="og:image" content="https://saool.io/assets/images/meta.png" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://saool.io/" />
            <meta property="twitter:title" content="ما هو سؤول" />
            <meta property="twitter:description" content="ما هو سؤول" />
            <meta property="twitter:image" content="https://saool.io/assets/images/meta.png" />
            <meta name="title" content="ما هو سؤول" />
        </Helmet>
            <h1>عن سؤول</h1>
            <CSSTransitionGroup
                className="card shadow-sm border-0 rounded mb-0"
                component="div"
                transitionName="fade"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={500}
                transitionAppear
                transitionAppearTimeout={300}>
                <div className={'mb-3 mt-3 ml-3 mr-3'}>
                <p>"سؤول" الاسم المشتق من المصدر اللغوي سأل، هي منصة اختبارات قصيرة ترفيهية تغطي الموضوعات العامة (على سبيل المثال: الرياضة، الصحة، الأفلام)، مدعومة بميزات تواصل فريدة تجعل من تجربة المستخدم استثنائية مقارنة بالمنصّات الشبيهة في المنطقة.</p>
                <p>تسعى "سؤول" أن تكون منصة الاختبارات الترفيهية القصيرة المفضلة في الوطن العربي، وستعمل لتحقيق ذلك في توسيع رقعة منتجات الاختبارات لتشمل الأنواع الأكثر شيوعًا (على سبيل المثال: الاختبارات الخطيّة ذات النتائج المحددة، والمسابقات التفاعليّة عبر الانترنت لمتنافسين حقيقين)، بالإضافة إلى نموذج أعمال واعد يركز على عموم المستخدمين&nbsp; وكذلك الشخصيات ذات التأثير في وسائل التواصل والشركات التي ترغب في فهم عملائها عبر اختبارات قصيرة ترفيهية.</p>
                <p>لتحقيق هدفها، ستدأب سؤول على تبنّي أعلى المعايير في تجربة المستخدم والتطوير، وستعمل المنصة على تطوير أعمالها عبر استحداث نماذج عمل مرنة وتجربتها خلال السنة الأولى.</p>
                </div>
            </CSSTransitionGroup>
        </div>
    </div>
);
}
