import React from "react";

const WebShare = ({ quizName, quizUrl }) => {
    const handleOnClick = () => {
        if (navigator.share) {
          navigator
            .share({
              title: `${quizName}`,
              text: `جرّب ${quizName} وشوف كم تقدر تجيب!`,
              url: document.location.href,
            })
            .then(() => {
              console.log('Successfully shared');
            })
            .catch(error => {
              console.error('Something went wrong sharing the blog', error);
            });
        }
      };

  return (
    <div
      onClick={handleOnClick}
      className="container"
      style={{ position: "relative", textAlign: "center", padding: "0px" }}>
      <img
        src="/assets/images/share.svg"
        className="w-100 scale"
        style={{ maxHeight: "200px" }}
      />
      <div
        style={{
          position: "absolute",
          top: "39%",
          left: "58%",
          transform: "translate(-50%, -50%)",
          fontWeight: "bold",
          fontSize: "20px",
        }}>
        {" "}
        شارك الاختبار مع من تحب 👏
      </div>
    </div>
  );
};

export default WebShare;
