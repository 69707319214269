import React from 'react'
import {hydrate,render} from 'react-dom'
import { createBrowserHistory } from 'history'
import ScrollToTop from './scrollToTop'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Router } from 'react-router-dom'

const history = createBrowserHistory()

  const rootElement = document.getElementById("root");
  if (rootElement.hasChildNodes()) {
    hydrate(
      <Router history={history}>
    <App />
    <ScrollToTop />
  </Router>, rootElement);
  } else {
    render(
      <Router history={history}>
      <App />
      <ScrollToTop />
    </Router>, rootElement);
  }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
