import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div className="container">
      <Helmet>
        <title>عن منصة سؤول</title>
        <meta name="description" content="عن منصة سؤول" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saool.io/" />
        <meta property="og:title" content="سؤول" />
        <meta
          property="og:description"
          content="سؤول أفضلل موقع يوفّر اختبارات ترفيهية في جميع الأصناف، أنمي، مسلسلات، اغاني، كيبوب، أعمال، اختبارات ذكاء، لغات ولهجات"
        />
        <meta
          property="og:image"
          content="https://saool.io/assets/images/meta.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://saool.io/" />
        <meta property="twitter:title" content="سؤول" />
        <meta
          property="twitter:description"
          content="سؤول أفضلل موقع يوفّر اختبارات ترفيهية في جميع الأصناف، أنمي، مسلسلات، اغاني، كيبوب، أعمال، اختبارات ذكاء، لغات ولهجات"
        />
        <meta
          property="twitter:image"
          content="https://saool.io/assets/images/meta.png"></meta>

        <meta name="title" content="عن سؤول" />
      </Helmet>
      <h1>تعريف المنتج</h1>
      <CSSTransitionGroup
        className="card shadow-sm border-0 rounded mb-0"
        component="div"
        transitionName="fade"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={500}
        transitionAppear
        transitionAppearTimeout={300}>
        <div className={"mb-3 mt-3 ml-3 mr-3"}>
          <p>
            <strong>
              تعد الاختبارات القصيرة الترفيهية والتي يقابلها المصطلح الإنجليزي "
            </strong>
            <strong>Trivia Quizzes</strong>
            <strong>
              " أحد أنواع المحتوى الترفيهي الرائج على الشبكة، حيث يشارك فيه
              المستخدمون باختلافهم في صناعة هذه الاختبارات ووضع أسئلتها ومن ثم
              مشاركتها مع الأصدقاء والاستمتاع بردّات فعلهم.{" "}
            </strong>
          </p>
          <p>
            <strong>
              تختلف الاختبارات من هذا النوع في آلية عملها وتقييم النتيجة
              النهائية فيها، وتكون على أنواع ومنها على سبيل&nbsp; المثال لا
              الحصر:{" "}
            </strong>
          </p>
          <ul>
            <li>
              <strong>
                الاختبارات ذات النتيجة الرقمية، حيث في نهايتها يحصل المستخدم عل
                نتيجة نهائية بناءً على عدد الأسئلة التي أجابها بشكل صحيح من
                مجموع الأسئلة الكلي (على سبيل المثال: اختبار اللهجات){" "}
              </strong>
            </li>
            <li>
              <strong>
                الاختبارات التقييمية، وفيها يتم تصنيف المستخدم تحت فئة معينة
                بناءً على إجاباته والتي تدعم ذلك التصنيف (على سبيل المثال:
                الاختبارات التي تحدد نوع شخصيتك من فلمك المفضل)
              </strong>
            </li>
          </ul>
          <p>
            <strong>
              وسيدعم سؤول مزيدًا من أنواع الاختبارات المختلفة محققًا بذلك رؤيته
              الطموحة ليكون
            </strong>{" "}
            منصة الاختبارات الترفيهية القصيرة المفضلة في الوطن العربي.
          </p>
        </div>
      </CSSTransitionGroup>
    </div>
  );
}
