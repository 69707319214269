import React, { useState } from 'react'
import axios from 'axios'


export default function Rating(props) {
    var [ratingId, setRatingId] = useState();
    var [buttonActive, setButtonActive] = useState(false);
    var [buttonDisabled, setButtonDisabled] = useState(false);
    var [feedback, setFeedback] = useState("");
    var [submitted, setSubmitted] = useState(false);
    

    function ratingHandler(value) {


        // Get Quiz Id then Submit value to it
        axios.post(`${process.env.REACT_APP_API_URL}/ratings`, {
      value: value,
      quiz: {id: props.quizId},
    },{ headers: { 'content-type': 'application/json' }})
      .then(function (response) {
        // Disable Other buttons
        setButtonDisabled(true);
        // Activate current Button
        setButtonActive(value)
        //  Show feedback part
        setRatingId(response.data.id);
        //&&Done
      })
    }

function feedbackHandler(event) {
    setFeedback(event.target.value);
}
    function feedbackSubmit(value) {
        axios.put(`${process.env.REACT_APP_API_URL}/ratings/${ratingId}`, {
            text:feedback,
          },{ headers: { 'content-type': 'application/json' }})
            .then(function (response) {
             // show success message
              setSubmitted(true);
            })
    }
    return (
        <div
            className="card shadow-sm border-0 p-4 rounded mb-3 "
            component="div"
            transitionName="">
            {submitted ?
                <div className="d-flex justify-content-center align-items-center">
                    <h1 className="font-weight-bold">شكرًا لك! <span role="img" aria-label="emoji">🧑‍🏫</span></h1>
                </div> :
                <div>
                    <h1 className="font-weight-bold">كيف كان الاختبار؟</h1>
                    <div className="d-flex justify-content-between mb-3">
                        <button className={`rating-button ${buttonActive === 4 ? 'rating-active' : ''} ${buttonDisabled ? 'rating-disabled' : ''}`} disabled={buttonDisabled} onClick={() => ratingHandler(4)}>
                            <div className="rating-top">
                                <span role="img" aria-label="emoji">😍</span>
                </div>
                            <div className="rating-bottom">
                                رهيب!
                    {/* <div className="rating-footer">
                                    122k
                </div> */}
                            </div>
                        </button>
                        <button className={`rating-button ${buttonActive === 3 ? 'rating-active' : ''} ${buttonDisabled ? 'rating-disabled' : ''}`} disabled={buttonDisabled} onClick={() => ratingHandler(3)}>
                            <div className="rating-top">
                            <span role="img" aria-label="emoji">😊</span>
                                
                </div>
                            <div className="rating-bottom">
                                جميل
                    {/* <div className="rating-footer">
                                    122k
                </div> */}
                            </div>
                        </button>
                        <button className={`rating-button ${buttonActive === 2 ? 'rating-active' : ''} ${buttonDisabled ? 'rating-disabled' : ''}`} disabled={buttonDisabled} onClick={() => ratingHandler(2)}>
                            <div className="rating-top">
                            <span role="img" aria-label="emoji">🙆</span>
                                
                </div>
                            <div className="rating-bottom">
                                OK..
                    {/* <div className="rating-footer">
                                    122k
                </div> */}
                            </div>
                        </button>
                        <button className={`rating-button ${buttonActive === 1 ? 'rating-active' : ''} ${buttonDisabled ? 'rating-disabled' : ''}`} disabled={buttonDisabled} onClick={() => ratingHandler(1)}>
                            <div className="rating-top">
                            <span role="img" aria-label="emoji">🌚</span>
                                
                </div>
                            <div className="rating-bottom">
                                GG
                    {/* <div className="rating-footer">
                                    122k
                </div> */}
                            </div>
                        </button>
                        <button className={`rating-button ${buttonActive === 0 ? 'rating-active' : ''} ${buttonDisabled ? 'rating-disabled' : ''}`} disabled={buttonDisabled} onClick={() => ratingHandler(0)}>
                            <div className="rating-top">
                            <span role="img" aria-label="emoji">🥴</span>
                                
                </div>
                            <div className="rating-bottom">
                                خاايس
                    {/* <div className="rating-footer">
                                    122k
                </div> */}
                            </div>
                        </button>


                    </div>
                    {buttonDisabled &&
                        <div>
                            <h1 className="font-weight-bold mt-3">شاركنا رأيك <span role="img" aria-label="emoji">🧑‍🏫</span></h1>
                            <div className="form-group">
                                <textarea className="form-control mb-3" id="feedback" rows="3" style={{ border: '2px solid #E0E0E0' }} onChange={feedbackHandler}></textarea>
                                <button className="btn btn-primary btn-block btn-md" style={{ borderRadius: '15px', paddingTop: '8px', paddingBottom: '8px' }} onClick={()=>feedbackSubmit()}>أرسل</button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}
