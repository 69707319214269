import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Quiz, { QuizLoading } from '../shared/components/Quiz'
import Result from '../shared/components/Result'
import { useRouteMatch, Redirect } from 'react-router-dom'
import Share from '../shared/components/Share'
import { Helmet } from 'react-helmet'



function QuizPage(props) {
  const [counter, setCounter] = useState(0);
  const [questionId, setQuestionId] = useState(1);
  const [score, setScore] = useState(0);
  const [answer, setAnswer] = useState();
  const [correctanswersCount, setCorrectanswersCount] = useState(0);
  const [result, setResult] = useState('');
  const [imageResult, setImageResult] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [redirectFlag, setRedirectFlag] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState('');
  const [relatedQuizzes, setrelatedQuizzes] = useState({});
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState({});
  const [questionsNumber, setquestionsNumber] = useState(0);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [answersCount, setAnswerCount] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let match = useRouteMatch()
  const quizTrimmed = match.params.quiz.replace(/-/g, " ")
  const resetQuiz = () => {
    setCounter(0)
    setQuestionId(1);
    setScore(0);
    setAnswer('');
    setCorrectanswersCount(0);
    setResult('');
    setCategory('');
    setType('');
  }

  useEffect(() => {
    setIsLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}/graphql`,
      method: 'post',
      data: {
        query: `query {
            quizzes(where:{title:"${quizTrimmed}"}){
                id
                type
                title
                keyword
                extended_description
                questions{
                    id
                    statement
                    type

                    point
                    image {
                        url
                    }
                    choices{
                        id
                        text
                        type
                        is_correct
                    }
                }
                category{
                    name
                    id
                }
                image{
                    url
                }
                categorical_result{
                  name
                  image{
                    url
                  }
                }
            }
        }`}
    }
    ).then(serverResponse => {
      const selectedQuiz = serverResponse.data.data.quizzes[0]
      if (selectedQuiz) {
        const shuffledAnswerOptions = selectedQuiz.questions.map(question =>
          shuffleArray(question.choices)
        )
        setQuestion(selectedQuiz.questions[0])
        setAnswerOptions(shuffledAnswerOptions[0])
        setquestionsNumber(selectedQuiz.questions.length)
        setTitle(selectedQuiz.title)
        setSelectedQuiz(selectedQuiz)
        setCategory(selectedQuiz.category.name)
        setType(selectedQuiz.type)
        axios({
          url: `${process.env.REACT_APP_API_URL}/graphql`,
          method: "post",
          data: {
            query: `query {
                categories(where:{name:"${selectedQuiz.category.name}"}){
                  quizzes{
                    id
                    type
                    title
                    extended_description
                    category{
                      name
                      id
                    }
                    image{
                        url
                    }
                }
              }
            }`}
        }).then(serverResponse => {
          setrelatedQuizzes(serverResponse.data.data.categories[0].quizzes)

        })
        setIsLoading(false);

      } else {
        setRedirectFlag(true)

      }
    })

  }, [props])

  const shuffleArray = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  const handleAnswerSelected = (event) => {
    setUserAnswer(event.currentTarget.value)
    if (questionId < questionsNumber) {
      setTimeout(() => {
        setNextQuestion()
      }, 500)
    } else {
      console.log("I am the getResult()", getResults());
      setTimeout(() => setResults(getResults()), 500)
    }
  }

  const setUserAnswer = (answer) => {
    var isCorrect = false;
    if (type === 'categorical') {
      isCorrect = answerOptions.filter(answers => answers.type === answer)[0].is_correct
    } else if (type === 'numerical') {
      isCorrect = answerOptions.filter(answers => answers.id === answer)[0].is_correct
    }
    setAnswerCount({ ...answersCount, [answer]: (answersCount[answer] || 0) + 1 })
    setAnswer(answer);
    if (isCorrect) {
      setScore(question.point + score)
      setCorrectanswersCount(correctanswersCount + 1)
    }
  }

  const setNextQuestion = () => {
    const tempCounter = counter + 1
    const tempQuestionId = questionId + 1
    setCounter(tempCounter);
    setQuestionId(tempQuestionId);
    setQuestion(selectedQuiz.questions[tempCounter])
    setAnswerOptions(selectedQuiz.questions[tempCounter].choices)
    setAnswer('')
  }

  const getResults = () => {
    const tempAnswerCount = answersCount
    const answersCountKeys = Object.keys(tempAnswerCount)
    const answersCountValues = answersCountKeys.map(key => answersCount[key])
    const maxAnswerCount = Math.max.apply(null, answersCountValues)
    return answersCountKeys.filter(key => answersCount[key] === maxAnswerCount)
  }

  const setResults = (result) => {
    if (result.length === 1) {
      setResult(result[0]);

      setImageResult(selectedQuiz.categorical_result.find(x => x.name === result[0]).image.url)
    } else {
      let index = Math.floor(Math.random() * Math.floor(result.length))
      setResult(result[index])
      if (type === 'categorical') 
        setImageResult(selectedQuiz.categorical_result.find(x => x.name === result[index]).image.url)
    }
  }

  const renderQuiz = () => {
    return (
      isLoading ?
        <QuizLoading /> :
        <Quiz
          answer={answer}
          answerOptions={answerOptions}
          questionId={questionId}
          question={question}
          questionTotal={questionsNumber}
          onAnswerSelected={handleAnswerSelected}
          isLoading={isLoading}
          type={type}
          quizTitle={title}
        />
    )
  }

  const renderResult = () => {
    axios.post(`${process.env.REACT_APP_ATTEMPTS_API}/quiz-attempts`, {
      result: correctanswersCount,
      quiz: selectedQuiz,
      questions_count: questionsNumber
    })
      .then(function (response) {

      })
    return <Result
      quizId={selectedQuiz.id}
      quizKeyword={selectedQuiz.keyword}
      resetQuiz={resetQuiz}
      quizTitle={title}
      quizType={selectedQuiz.type}
      quizResult={result}
      quizResultImage={imageResult}
      scoreResult={score}
      answeredResult={correctanswersCount}
      questionsNumber={questionsNumber}
      relatedQuizzes={relatedQuizzes}
      changeStarted={props.changeStarted} />
  }

  if (redirectFlag) {
    return <Redirect to="/" />
  }
  if (redirectFlag) {
    return <Redirect to="/" />
  }

  return (
    <div className="App">
      {selectedQuiz && 
        <Helmet>
          <title>سؤول - {selectedQuiz.title} - الاختبار</title>
          <meta name="description" content={(selectedQuiz.extended_description.length > 160) ? `${selectedQuiz.extended_description.substring(0, 160)}` : `سؤول موقع اختبارات قصيرة ترفيهية ممتعة`} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://www.saool.io/`} />
          <meta property="og:title" content={selectedQuiz.title + ` - ` + selectedQuiz.category.name} />
          <meta property="og:description" content={(selectedQuiz.extended_description.length > 160) ? `${selectedQuiz.extended_description.substring(0, 160)}` : `سؤول موقع اختبارات قصيرة ترفيهية ممتعة`} />
          <meta property="og:image" content={"https://saool.io/assets/images/seo/" + selectedQuiz.id + ".jpeg"} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.saool.io/" />
          <meta property="twitter:title" content={selectedQuiz.title + ` - ` + selectedQuiz.category.name} />
          <meta property="twitter:description" content={(selectedQuiz.extended_description.length > 160) ? `${selectedQuiz.extended_description.substring(0, 160)}` : `سؤول موقع اختبارات قصيرة ترفيهية ممتعة`} />
          <meta property="twitter:image" content={"https://saool.io/assets/images/seo/" + selectedQuiz.id + ".jpeg"}></meta>
          <meta name="title" content={selectedQuiz.title + " - "  + selectedQuiz.category.name + " - الاختبار"} />
        </Helmet>
      }
      <div className="App-header">
      </div>
      {result ?
        renderResult()
        : renderQuiz()}


      {/* <div className="">
        <Share
          quizTitle={title}
          quizPath={"categories/" + title.replace(/ /g, "-") + "/" + category.replace(/ /g, "-")} />
      </div> */}

    </div>
  )
}


export default QuizPage