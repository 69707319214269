import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { CSSTransitionGroup } from "react-transition-group";
import Skeleton from "react-loading-skeleton";
import RandomQuiz from "shared/components/RandomQuiz";
import QuizPage from "./QuizPage";
import GoogleAds from "shared/components/GoogleAds";
import WebShare from "shared/components/WebShare";

export function QuizInfo(props) {
  // Route is needed to check for the QuizID and the TrimmedQuiz
  const match = useRouteMatch();
  // History is used update the URL to the new format
  const history = useHistory();

  const [quiz, setQuiz] = useState([{}]);
  const [examTakers, setExamTakers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [quizID, setQuizID] = useState(match.params.quizID);

  let isNewRoute = typeof match.params.quizID !== "undefined";
  let quizTrimmed = "";
  let quizFilter = "";

  if (isNewRoute) {
    quizFilter = `{id: "${match.params.quizID}"}`;
  } else {
    quizTrimmed = match.params.quiz.replace(/-/g, " ");
    quizFilter = `{title: "${quizTrimmed}"}`;
  }

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  function updateRoute(quiz) {
    history.push(`/quiz/${quiz.id}/${quiz.title.replace(/\s+/g, "-")}`);
  }

  function startQuiz(value, quizID) {
    setIsStarted(value);
    setQuizID(quizID);
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsStarted(false);
      var quiz;
      await axios({
        url: `${process.env.REACT_APP_API_URL}/graphql`,
        method: "post",
        data: {
          query: `query {
          quizzes(where:${quizFilter}){
              id
              type
              description
              extended_description
              title
              video
              keyword
              questions {
                id
              }
              category{
                  name
                  id
              }
              
              image{
                  url
              }
          }
    }`,
        },
      }).then((serverResponse) => {
        quiz = serverResponse.data.data.quizzes[0];
        setQuizID(quiz.id);
      });
      if (quiz == null) {
        return <Redirect to="/" />;
      }
      setQuiz(quiz);
      updateRoute(quiz);

      await axios({
        url: `${process.env.REACT_APP_ATTEMPTS_API}/graphql`,
        method: "post",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjI4NDMzNjQxLCJleHAiOjE2MzEwMjU2NDF9.ydboMZ5ZuQBUiPzQ5c9RGTrZPZODXntULWhLWzYP5rA",
        },
        data: {
          query: `{
            quizAttemptsCount(where:{quiz:${quiz.id}})
          }`,
        },
      }).then((serverResponse) => {
        //console.log(serverResponse);
        if (serverResponse.data.data != null)
          setExamTakers(serverResponse.data.data.quizAttemptsCount);
        else setExamTakers(0);
      });
      setIsLoading(false);
    };

    fetchData();
  }, [quizTrimmed, quizID]);

  return isStarted ? (
    <QuizPage changeStarted={startQuiz} />
  ) : (
    <div>
      {!isLoading && quiz.questions ? (
        <div>
          <Helmet>
            <title>
              {quiz.title} | {quiz.keyword} | سؤول
            </title>
            <meta
              name="description"
              content={
                quiz.extended_description.length > 160
                  ? `${quiz.extended_description.substring(0, 160)}`
                  : `سؤول موقع اختبارات قصيرة ترفيهية ممتعة`
              }
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://saool.io/`} />
            <meta
              property="og:title"
              content={quiz.title + ` - ` + quiz.category.name}
            />
            <meta
              property="og:description"
              content={
                quiz.extended_description.length > 160
                  ? `${quiz.extended_description.substring(0, 160)}`
                  : `سؤول موقع اختبارات قصيرة ترفيهية ممتعة`
              }
            />
            <meta
              property="og:image"
              content={
                "https://saool.io/assets/images/seo/" + quiz.id + ".jpeg"
              }
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://saool.io/" />
            <meta
              property="twitter:title"
              content={quiz.title + ` - ` + quiz.category.name}
            />
            <meta
              property="twitter:description"
              content={
                quiz.extended_description.length > 160
                  ? `${quiz.extended_description.substring(0, 160)}`
                  : `سؤول موقع اختبارات قصيرة ترفيهية ممتعة`
              }
            />
            <meta
              property="twitter:image"
              content={
                "https://saool.io/assets/images/seo/" + quiz.id + ".jpeg"
              }></meta>
            <meta
              name="title"
              content={
                quiz.title + ` | ` + quiz.category.name + " | " + quiz.keyword
              }
            />
          </Helmet>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="row justify-content-end">
                  <div
                    className="col-xs-3"
                    style={{
                      marginBottom: "-60px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      zIndex: "1",
                    }}>
                    <CSSTransitionGroup
                      className="card border-light border rounded mb-0"
                      component="div"
                      transitionName="fade"
                      transitionEnterTimeout={300}
                      transitionLeaveTimeout={500}
                      transitionAppear
                      transitionAppearTimeout={300}>
                      <img
                        style={{
                          height: "120px",
                          width: "120px",
                          margin: "0px",
                          borderRadius: "5px",
                        }}
                        className="img-fluid"
                        src={process.env.REACT_APP_API_URL + quiz.image.url}
                        alt={quiz.title}
                      />
                    </CSSTransitionGroup>
                  </div>
                </div>
                <CSSTransitionGroup
                  className="card shadow-sm border-0 rounded mb-3 pb-4"
                  component="div"
                  style={{ minHeight: "250px" }}
                  transitionName="fade"
                  transitionEnterTimeout={300}
                  transitionLeaveTimeout={500}
                  transitionAppear
                  transitionAppearTimeout={300}>
                  <div className="row d-flex justify-content-between px-2">
                    <div className="col-4 ribbon-up">
                      <div
                        className="card border-0 px-2 py-1"
                        style={{
                          background:
                            "url(/assets/images/arrow-up-badge.svg) no-repeat",
                        }}>
                        <small className="text-center pt-2 mb-1">
                          <b>نوع الاختبار</b>
                        </small>
                        {quiz.type === "numerical" ? (
                          <small className="text-center">تحدي أعلى نتيجة</small>
                        ) : (
                          <small className="text-center">تحدي التصنيف</small>
                        )}
                      </div>
                    </div>
                    <div className="col-4 ribbon-down">
                      <div
                        className="card border-0 text-center px-3 py-1"
                        style={{
                          background:
                            "url(/assets/images/arrow-down-badge.svg) no-repeat",
                          backgroundPosition: "38% top",
                        }}>
                        <small className="text-center pt-2 mb-1">
                          <b>عدد المختبرين</b>
                        </small>
                        <small className="text-center">
                          {Number(examTakers).toLocaleString()}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className={"mb-0 mt-3 ml-3 mr-3"}>
                    <h1 className="font-weight-bold mb-2 w-100">
                      {quiz.title}
                    </h1>
                    <p className="text-primary mb-1">
                      مكون من {quiz.questions.length}
                      {quiz.questions.length < 11 && <span> أسئلة</span>}
                      {quiz.questions.length >= 11 && <span> سؤالًا</span>}
                    </p>
                    {quiz.description ? (
                      <p>{quiz.description}</p>
                    ) : (
                      <img
                        className="my-4 float-state"
                        src="/assets/images/empty-state.svg"
                        alt="الدنيا مليئة بالفضائيين"
                      />
                    )}
                  </div>
                </CSSTransitionGroup>
                <a
                  href="javascript:;"
                  onClick={() => {
                    startQuiz(true, quizID);
                  }}>
                  <button className="btn btn-lg btn-block fire shadow-sm border-0 rounded font-weight-bold my-4 text-right">
                    ابدأ الاختبار يا مدير
                  </button>
                </a>
              </div>

              <GoogleAds />

              <div className="col-lg-4 col-md-6">
                {quiz.video && (
                  <div
                    className="card shadow-sm border-0 rounded mb-3 pt-1 pb-4"
                    component="div"
                    transitionName="fade">
                    <div className={"mb-0 mt-3 ml-3 mr-3"}>
                      <h2 className="font-weight-bold">
                        فيديو عن {quiz.keyword}
                      </h2>
                      <iframe
                        src={`https://www.youtube.com/embed/${getId(
                          quiz.video
                        )}`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="youtube video"
                        allowfullscreen></iframe>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-8">
                {quiz.extended_description && (
                  <div
                    className="card shadow-sm border-0 rounded mb-3 pt-1 pb-4"
                    component="div"
                    transitionName="fade">
                    <div
                      className={"mb-0 mt-3 ml-3 mr-3  extended-description"}>
                      <h2 className="font-weight-bold">
                        {" "}
                        اختبار {quiz.keyword}
                      </h2>
                      <p
                        className="collapse "
                        id="description"
                        aria-expanded="false">
                        {quiz.extended_description.substring(
                          0,
                          Math.round(quiz.extended_description.length * 0.25)
                        )}
                      </p>
                      <h2 className="font-weight-bold">
                        اختبر معلوماتك عن {quiz.keyword}
                      </h2>
                      <p
                        className="collapse "
                        id="description"
                        aria-expanded="false">
                        {quiz.extended_description.substring(
                          Math.round(quiz.extended_description.length * 0.25),
                          Math.round(quiz.extended_description.length * 0.5)
                        )}
                      </p>
                      <h2 className="font-weight-bold">
                        كويز عن {quiz.keyword}
                      </h2>
                      <p
                        className="collapse "
                        id="description"
                        aria-expanded="false">
                        {quiz.extended_description.substring(
                          Math.round(quiz.extended_description.length * 0.5),
                          Math.round(quiz.extended_description.length * 0.75)
                        )}
                      </p>
                      <h2 className="font-weight-bold">
                        أسئلة عن {quiz.keyword}
                      </h2>
                      <p
                        className="collapse "
                        id="description"
                        aria-expanded="false">
                        {quiz.extended_description.substring(
                          Math.round(quiz.extended_description.length * 0.75),
                          quiz.extended_description.length
                        )}
                      </p>
                      <p className="mb-0">
                        <a
                          href="#"
                          className="btn btn-warning btn-block text-dark text-center collapsed py-2"
                          style={{ fontSize: "14px", borderRadius: "13px" }}
                          role="button"
                          data-toggle="collapse"
                          data-target="#description"
                          aria-expanded="false"
                          aria-controls="description">
                          .
                        </a>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="row justify-content-end">
                <div
                  className="col-xs-3"
                  style={{
                    marginBottom: "-60px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    zIndex: "1",
                  }}>
                  <div className="card border-light border rounded mb-0">
                    <img
                      style={{ height: "95px", width: "95px", margin: "10px" }}
                      className="img-fluid"
                      src="/assets/images/placeholder-1.png"
                      alt="placeholder"
                    />
                  </div>
                </div>
              </div>

              <div className="card shadow-sm border-0 rounded mb-3 pt-5 pb-4">
                <div className={"mb-0 mt-3 ml-3 mr-3"}>
                  <h2 className="font-weight-bold mb-3 w-60">
                    <Skeleton />
                  </h2>
                  <p>
                    <Skeleton />
                  </p>
                  <h2 className="font-weight-bold pt-1 mb-3">
                    <Skeleton />
                  </h2>
                </div>
              </div>

              <Link title={match.url} to={`${match.url}/start`}>
                <Skeleton />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizInfo;
