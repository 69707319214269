
import React from 'react'
import { Link} from 'react-scroll'


export default class Filter extends React.Component {
    constructor(props) {
      super(props);
      this.props.categories.forEach(category => {
        //console.log(category.id)
        this[category.id] = React.createRef();
        //console.log(this[category.id])
      });
    }
  
    scrollToCategory = id => {
      if(this[id].current){
      this[id].current.scrollIntoView({ inline: "start" });
      }
    };
  
    render() {
      return (
        <>
          {this.props.categories.map((category, i) => {
            return <li key={category.id} ref={this[category.id]}>
              <Link
                className="btn btn-outline-info text-white border-white d-flex"
                activeClass="bg-white text-info"
                to={category.ref}
                spy={true}
                smooth={true}
                offset={-130}
                duration={500}
                onSetActive={() => this.scrollToCategory(category.id)}>
                {category.name}
              </Link>
              </li>
          })}
        </>
      )
    }
  }