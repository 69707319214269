import React,{lazy, Suspense} from 'react'

import { animated, useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';

const QuizCard = lazy(() => import('../../pages/QuizCard'));

const renderLoader = () => <Skeleton/>;



const clamp = (value, clampAt = 30) => {
  if (value > 0) {
    return value > clampAt ? clampAt : value;
  } else {
    return value < -clampAt ? -clampAt : value;
  }
};

export default function Slider (props) {
  const [style, set] = useSpring(() => ({
    transform: "perspective(600px) rotateY(0deg)"
  }));

  const bind = useScroll(event => {
    set({
      transform: `perspective(600px) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`
    });
  });

  

  return (
    <div className="carousel w-100">

<div className="slider-container  px-2 pt-0" {...bind()}>
        {props.quizzes.map((quiz, i)  => (
          <Link title={quiz.title} key={i} style={{ margin:'10px',marginLeft: '5px' ,marginRight:'5px'}} onClick={() => {props.changeStarted(false, quiz.id)}} to={`/quiz/${quiz.id}/${quiz.title.replace(/ /g, "-")}`}>
          
          <animated.div
            key={i}
            className="slider-card d-flex align-items-stretch justify-content-between"
            style={{
              ...style,
            }}
          > <div className="m-1 carousel-card d-flex justify-content-between h-100 w-100">
            <Suspense fallback={renderLoader()}>
          <QuizCard quiz={quiz} />
          </Suspense>
        </div></animated.div>
        </Link>
        ))}
        
      </div>
    </div>
  )
}
