import React from 'react'
import PropTypes from 'prop-types'

function QuestionCount (props) {
  return (
    <div className="questionCount d-flex flex-column">
      <label className="font-weight-bold text-primary" style={{fontSize:'12px'}}>سؤال رقم <span>{props.counter}</span> من <span>{props.total}</span></label>
    </div>
  )
}

QuestionCount.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default QuestionCount
