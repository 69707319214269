import React from 'react'
import { Link } from 'react-router-dom'
export default function Header(props) {
    return (

      <nav className="navbar navbar-expand-md navbar-light bg-white rounded-bottom shadow-xs justify-content-start mb-3">
        <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <img width="25px" src="/assets/images/burger.svg" alt="sidemenu" />
        </button>
        <a className="navbar-brand font-weight-bold text-dark flex-grow-1 ml-5 mr-0  text-center" href="/"><img className="mx-auto animate__animated animate__flipInX animate__faster" width="100px" src="/assets/images/logo.png" alt="سؤول"></img></a>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto pr-1">
            <li className="nav-item active">
              <Link title="سؤول" className="nav-link" to="/">الرئيسية</Link>
            </li>
            <li className="nav-item">
              <Link title="عن سؤول" className="nav-link" to="/saool">عن سؤول</Link>
            </li>
            <li className="nav-item">
              <Link title="منتج سؤول" className="nav-link" to="/about">عن المنتج</Link>
            </li>
            <li className="nav-item">
              <Link title="تواصل معنا" className="nav-link" to="/contactus">تواصل معنا</Link>
            </li>
            <li className="nav-item">
              <Link title="الخصوصية" className="nav-link" to="/privacy">سياسة الخصوصية</Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }

