import React from 'react'
import Header from './layout/Header'
import Footer from './layout/Footer'
import Routes from './routes'
import 'bootstrap/dist/js/bootstrap.js'
import './scss/app.scss'

import { Helmet } from 'react-helmet'

export default function App() {

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Saool سؤول",
    "url": "https://saool.io",
    "address": "Saudi Arabia",
    "sameAs": [
      "https://www.facebook.com/SaoolTrivia",
      "https://twitter.com/SaoolTrivia"
    ]
  }
  return (
    <div>
      <Helmet>
        <title>سؤول - أفضل موقع اختبارات ترفيهية في العالم</title>
        <meta name="description" content="سؤول أفضل موقع يوفّر اختبارات ترفيهية في جميع الأصناف، أنمي، مسلسلات، اغاني، كيبوب، أعمال، اختبارات ذكاء، لغات ولهجات" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saool.io/" />
        <meta property="og:title" content="سؤول" />
        <meta property="og:description" content="سؤول أفضل موقع يوفّر اختبارات ترفيهية في جميع الأصناف، أنمي، مسلسلات، اغاني، كيبوب، أعمال، اختبارات ذكاء، لغات ولهجات" />
        <meta property="og:image" content="https://saool.io/assets/images/meta.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://saool.io/" />
        <meta property="twitter:title" content="سؤول" />
        <meta property="twitter:description" content="سؤول أفضل موقع يوفّر اختبارات ترفيهية في جميع الأصناف، أنمي، مسلسلات، اغاني، كيبوب، أعمال، اختبارات ذكاء، لغات ولهجات" />
        <meta property="twitter:image" content="https://saool.io/assets/images/meta.png"></meta>
        <meta name="title" content="عن سؤول" />

        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div>
        <Header />
        <Routes />
      </div>
      <div id="767232344" dangerouslySetInnerHTML={{ __html: '' }}></div>
      <Footer />
    </div>
  )
}
