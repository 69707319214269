/* eslint-disable import/no-unused-modules */
import React, { useState, useEffect, lazy, Suspense } from "react";
import _ from "lodash";
import axios from "axios";
import Scrollspy from "react-scrollspy";

//import * as Scroll from 'react-scroll';
import { Link } from "react-scroll";
import { Link as Route } from "react-router-dom";

import Headroom from "react-headroom";
import Skeleton from "react-loading-skeleton";

import RandomQuiz from "shared/components/RandomQuiz";
import Filter from "shared/components/Filter";
//import GoogleAds from 'shared/components/GoogleAds'
//import GoogleAdsense from 'react-adsense-google';

const renderLoader = () => <Skeleton />;
const QuizCard = lazy(() => import("../pages/QuizCard"));
export default function Home(props) {
  const [data, setData] = useState([{ quizzes: [] }]);
  const [isLoading, setIsLoading] = useState(true);

  const categories = [
    { id: 1, name: "قسم الأنمي 🦸🏼", ref: "قسم الأنمي 🦸🏼" },
    { id: 2, name: "الفيديو قيمز 🎮", ref: "الفيديو قيمز 🎮" },
    { id: 3, name: "الموسيقى 🎶", ref: "الموسيقى 🎶" },
    { id: 4, name: "السينما 🎬", ref: "السينما 🎬" },
    { id: 11, name: "كيبوب KPOP ✨", ref: "كيبوب KPOP ✨" },
    { id: 5, name: "لغات ولهجات 🧑‍🏫", ref: "لغات ولهجات 🧑‍🏫" },
    { id: 6, name: "رياضة ومتعة ⚽️", ref: "رياضة ومتعة ⚽️" },
    { id: 7, name: "العااااام 😎", ref: "العااااام 😎" },
    { id: 8, name: "قسم الفلة 🤪", ref: "قسم الفلة 🤪" },
    { id: 9, name: "بزنس وأعمال 💻", ref: "بزنس وأعمال 💻" },
    { id: 10, name: "همة حتى القمة 90 🇸🇦", ref: "همة حتى القمة 90 🇸🇦" },
  ];
  const categoriesForScroll = categories.map((x) => x.name);
  // var Element = Scroll.Element;

  // var scroller = Scroll.scroller;
  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      var response;
      await axios({
        url: `${process.env.REACT_APP_API_URL}/graphql`,
        method: "post",
        data: {
          query: `query {
            quizzes{
                id
                type
                title
                category{
                  name
                  id
                  order
                }
                image{
                    url
                }
            }
        }`,
        },
      }).then((serverResponse) => {
        response = serverResponse.data.data.quizzes;
      });
      const result = _(response)
        .groupBy((x) => x.category.name)
        .map((value, key) => ({
          category: { id: key, order: value[0].category.order },
          quizzes: value,
        }))
        .sortBy((x) => x.category.order)
        .value();
      setData(result);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  function createLoader() {
    var loaders = [];
    loaders.push(
      <div key="1" className="row mb-5">
        <img
          style={{ margin: "auto" }}
          src="assets/images/Spinner-1-transparent.svg"
          alt="loader"
        />
      </div>
    );
    return loaders;
  }

  return (
    <div>
      <div className="container mt-4">
        <Headroom
          style={{
            WebkitTransition: "all .5s ease-in-out",
            MozTransition: "all .5s ease-in-out",
            OTransition: "all .5s ease-in-out",
            transition: "all .5s ease-in-out",
          }}>
          <Scrollspy
            id="filter"
            items={categoriesForScroll}
            currentClassName="is-current"
            className="d-flex bg-blue filter list-unstyled py-3 pr-1">
            <Filter categories={categories} />
          </Scrollspy>
        </Headroom>
        <div className="row justify-content-md-center">
          <div className="col-lg-4 col-md-6">
            <div className="row justify-content-md-center">
              <div className="col-md-12  d-flex justify-content-between align-items-stretch">
                <a href="http://onelink.to/saool">
                  <img
                    className="pb-3 img-fluid"
                    src="assets/images/mobile-app-banner.png"
                    alt="تحميل تطبيق سؤول"
                  />
                </a>
              </div>
              <h2 className="col-md-12" style={{ fontWeight: "800" }}>
                أرهب أقسام الاختبارات
              </h2>
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="category" style={{ width: "47%" }}>
                    <Link
                      activeClass="active"
                      to="قسم الأنمي 🦸🏼"
                      offset={-150}
                      spy={true}
                      smooth={true}
                      duration={500}>
                      <div
                        className="mb-3 d-flex justify-content-around overflow-hidden px-3 rounded"
                        style={{
                          background:
                            "linear-gradient(45deg, rgba(42,183,202,1) 50%, rgba(254,215,102,1) 100%)",
                        }}>
                        <div className="d-flex flex-column justify-content-between">
                          <h4 className="card-text font-weight-bold">الأنمي</h4>
                          <p className="card-text" style={{ fontSize: "45px" }}>
                            <span rold="img" aria-label="emoji">
                              🦸
                            </span>
                          </p>
                        </div>
                        <div>
                          <img
                            src="assets/images/categories/anime.png"
                            alt="قسم الأنمي 🦸🏼"
                            className="pt-2"
                            height="150"
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="category" style={{ width: "47%" }}>
                    <Link
                      activeClass="active"
                      to="الفيديو قيمز 🎮"
                      offset={-150}
                      spy={true}
                      smooth={true}
                      duration={500}>
                      <div
                        className="mb-3 d-flex justify-content-around overflow-hidden px-3 pr-4 rounded"
                        style={{
                          background:
                            "linear-gradient(225deg, rgba(254,171,102,1) 50%, rgba(254,215,102,1) 100%)",
                        }}>
                        <div className="d-flex flex-column justify-content-between">
                          <h4 className="card-text font-weight-bold">
                            الفيديو قيمز
                          </h4>
                          <p className="card-text" style={{ fontSize: "45px" }}>
                            <span aria-label="emoji" role="img">
                              🎮
                            </span>
                          </p>
                        </div>
                        <div>
                          <img
                            src="assets/images/categories/video-games.png"
                            alt="video games"
                            className="pt-2"
                            height="150"
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div
                      className="category overflow-hidden"
                      style={{ width: "30%" }}>
                      <Link
                        activeClass="active"
                        to="الموسيقى 🎶"
                        offset={-150}
                        spy={true}
                        smooth={true}
                        duration={500}>
                        <div
                          className="mb-3 d-flex justify-content-around overflow-hidden pr-3 rounded"
                          style={{
                            background:
                              "linear-gradient(45deg, rgba(254,215,102,1) 50%, rgba(254,240,196,1) 100%)",
                          }}>
                          <div className="d-flex flex-column justify-content-between">
                            <h6 className="card-text font-weight-bold">طرب</h6>
                            <p
                              className="card-text"
                              style={{ fontSize: "25px" }}>
                              <span aria-label="emoji" role="img">
                                🎹
                              </span>
                            </p>
                          </div>
                          <div>
                            <img
                              className="mt-2"
                              src="assets/images/categories/music.png"
                              alt="موسيقى"
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div
                      className="category overflow-hidden"
                      style={{ width: "30%" }}>
                      <Link
                        activeClass="active"
                        to="السينما 🎬"
                        offset={-150}
                        spy={true}
                        smooth={true}
                        duration={500}>
                        <div
                          className="mb-3 d-flex justify-content-around overflow-hidden pr-3 rounded"
                          style={{
                            background:
                              "linear-gradient(45deg, rgba(254,74,73,1) 50%, rgba(255,167,166,1) 100%)",
                          }}>
                          <div className="d-flex flex-column justify-content-between">
                            <h6 className="card-text font-weight-bold text-white">
                              سينما
                            </h6>
                            <p
                              className="card-text"
                              style={{ fontSize: "25px" }}>
                              <span aria-label="emoji" role="img">
                                🎬
                              </span>
                            </p>
                          </div>
                          <div>
                            <img
                              className="mt-2"
                              src="assets/images/categories/movies.png"
                              alt="أفلام"
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div
                      className="category overflow-hidden"
                      style={{ width: "30%" }}>
                      <Link
                        activeClass="active"
                        to="رياضة ومتعة ⚽️"
                        offset={-150}
                        spy={true}
                        smooth={true}
                        duration={500}>
                        <div
                          className="mb-3 d-flex justify-content-around overflow-hidden pr-2 rounded"
                          style={{
                            background:
                              "linear-gradient(45deg, rgba(254,182,102,1) 50%, rgba(254,231,205,1) 100%)",
                          }}>
                          <div className="d-flex flex-column justify-content-between">
                            <h6 className="card-text font-weight-bold">
                              رياضة
                            </h6>
                            <p
                              className="card-text"
                              style={{ fontSize: "25px" }}>
                              <span aria-label="emoji" role="img">
                                ⚽
                              </span>
                            </p>
                          </div>
                          <div>
                            <img
                              className="mt-2"
                              src="assets/images/categories/sport.png"
                              alt="رياضة"
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {data[0].quizzes.length > 0 && (
              <div className="row justify-content-sm-center">
                <RandomQuiz />
              </div>
            )}
          </div>
          {/* <GoogleAds/> */}
          {/* <GoogleAdsense
            adClient="ca-pub-2635048179627118"
            adSlot="8149907432"
            style={{'display': 'block'}}
            adFormat=''
            fullWidthResponsive='true'/> */}

          <div className="col-lg-4 col-md-6">
            {isLoading ? (
              <div className="container">{createLoader()}</div>
            ) : (
              data[0].quizzes.length > 0 &&
              data.map((item, i) => (
                <div key={i} className="">
                  <div
                    className="row justify-content-md-center"
                    data-spy="scroll"
                    data-target="#filter"
                    id={item.category.id}>
                    <div className="col-md-12">
                      <h1
                        name={item.category.id}
                        className="col-12 mb-2"
                        style={{ fontWeight: "800" }}>
                        {item.category.id}
                      </h1>
                      {/* <Slider key={i} quizzes={item.quizzes.reverse()} /> */}
                      <div className="row">
                        {item.quizzes.reverse().map((quiz, i) => (
                          <Route
                            className="col-6 col-md-6 mb-3"
                            title={quiz.title}
                            key={i}
                            to={`/quiz/${quiz.id}/${quiz.title.replace(
                              / /g,
                              "-"
                            )}`}>
                            <div
                              key={i}
                              className="slider-card d-flex align-items-stretch justify-content-between">
                              {" "}
                              <div className="m-1 carousel-card d-flex justify-content-between h-100 w-100">
                                <Suspense fallback={renderLoader()}>
                                  <QuizCard quiz={quiz} />
                                </Suspense>
                              </div>
                            </div>
                          </Route>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
