import React, { useEffect} from 'react'

export default function GoogleAds(props) {
  const { currentPath } = props

  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [currentPath])

    return (
      <div key={currentPath}>
        <ins
          className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client='ca-pub-2635048179627118'
          data-ad-slot='8149907432'
          data-full-width-responsive="true"></ins>
      </div>
    );
}