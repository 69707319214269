import React from "react";
import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import Slider from "./Slider";
import RandomQuiz from "./RandomQuiz";
import Rating from "./Rating";
import WebShare from "./WebShare";

function Result(props) {
  var categoricalGifs = [
    "/assets/images/gifs/categorical/wow1.gif",
    // "/assets/images/gifs/categorical/wow2.gif",
    // "/assets/images/gifs/categorical/wow3.gif",
    "/assets/images/gifs/categorical/wow4.gif",
    "/assets/images/gifs/categorical/wow5.gif",
  ];

  var highScoreGifs = [
    // "/assets/images/gifs/high/high1.gif",
    // "/assets/images/gifs/high/high2.gif",
    // "/assets/images/gifs/high/high3.gif",
    "/assets/images/gifs/high/high4.gif",
    // "/assets/images/gifs/high/high5.gif",
  ];

  var medScoreGifs = [
    // "/assets/images/gifs/medium/medium1.gif",
    // "/assets/images/gifs/medium/medium2.gif",
    // "/assets/images/gifs/medium/medium3.gif",
    // "/assets/images/gifs/medium/medium4.gif",
    "/assets/images/gifs/medium/medium5.gif",
  ];

  var lowScoreGifs = [
    "/assets/images/gifs/low/low1.gif",
    "/assets/images/gifs/low/low2.gif",
    "/assets/images/gifs/low/low3.gif",
    "/assets/images/gifs/low/low4.gif",
    "/assets/images/gifs/low/low5.gif",
  ];

  return (
    <CSSTransitionGroup
      className="container"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}>
      <div className="row justify-content-sm-center">
        <div className="col-lg-4 col-md-6">
          <CSSTransitionGroup
            className="card shadow-sm border-0 rounded mb-3 text-center overflow-hidden"
            component="div"
            transitionName="fade"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={500}
            transitionAppear
            transitionAppearTimeout={300}>
            <div className="result">
              {props.quizType === "numerical" && (
                <div>
                  <div className="gif-watermark">
                    {props.answeredResult / props.questionsNumber > 0.65 && (
                      <img
                        style={{ width: "100%", maxHeight: "200px" }}
                        src={
                          highScoreGifs[
                            Math.floor(Math.random() * highScoreGifs.length)
                          ]
                        }
                        alt="درجة مرتفعة"
                      />
                    )}
                    {props.answeredResult / props.questionsNumber <= 0.65 &&
                      props.answeredResult / props.questionsNumber >= 0.35 && (
                        <img
                          style={{ width: "100%", maxHeight: "200px" }}
                          src={
                            medScoreGifs[
                              Math.floor(Math.random() * medScoreGifs.length)
                            ]
                          }
                          alt="درجة متوسطة"
                        />
                      )}
                    {props.answeredResult / props.questionsNumber < 0.35 && (
                      <img
                        style={{ width: "100%", maxHeight: "200px" }}
                        src={
                          lowScoreGifs[
                            Math.floor(Math.random() * lowScoreGifs.length)
                          ]
                        }
                        alt="درجة ضعيفة"
                      />
                    )}
                  </div>
                  <div className="">
                    <div
                      className="mx-auto card border-0 px-5 pt-2"
                      style={{ borderRadius: "15px" }}>
                      <h1 className="text-center font-weight-bold">
                        نتيجتك في {props.quizTitle} هي
                      </h1>
                      <div className="d-flex justify-content-around justify-content-lg-around align-items-center">
                        <h2
                          className="text-center text-warning"
                          style={{ fontSize: "45px" }}>
                          {props.answeredResult}
                        </h2>
                        <p
                          className="display-4 text-center text-primary font-weight-bold"
                          style={{
                            display: "block",
                            fontSize: "25px",
                            lineHeight: "8px",
                          }}>
                          من
                        </p>
                        <h2
                          className="text-center text-primary"
                          style={{ fontSize: "45px" }}>
                          {props.questionsNumber}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {props.quizType === "categorical" && (
                <div>
                  <img
                    src={
                      props.quizResultImage !== ""
                        ? process.env.REACT_APP_API_URL + props.quizResultImage
                        : categoricalGifs[Math.floor(Math.random() * 5)]
                    }
                    style={{ width: "100%" }}
                    alt="اختبار النوع"
                  />
                  <div className="mt-3">
                    <h2 className="text-center">
                      نتيجتك في {props.quizKeyword} هي
                    </h2>
                  </div>
                  <div className="p-4">
                    <h1 className="text-center text-primary">
                      {props.quizResult}
                    </h1>
                  </div>
                </div>
              )}
              {props.quizType === "" && <strong>{props.scoreResult}</strong>}

              <div className="animate__animated animate__bounceIn animate__faster pt-1 px-3">
                <p className="text-center mb-1">
                  <small>صور الشاشة وشارك نتيجتك!</small>
                </p>
              </div>
            </div>
          </CSSTransitionGroup>
        </div>
        
        <WebShare quizName={props.quizTitle} />

        {/* <button className="btn btn-primary btn-lg btn-block shadow-sm border-0 rounded font-weight-bold mb-3">شارك نتيجتك</button> */}
        <h2 className="col-md-8 mb-2" style={{ fontWeight: "bold" }}>
          جرب ذي كمان، حتعجبك!
        </h2>
        <div className="col-md-8">
          <Slider
            quizzes={props.relatedQuizzes
              .reverse()
              .filter((item) => item.id !== props.quizId)}
            changeStarted={props.changeStarted}
          />
        </div>
        <div className="col-lg-4 col-md-6">
          <Rating quizId={props.quizId} />
        </div>
        {/* <RandomQuiz />    */}
      </div>
    </CSSTransitionGroup>
  );
}

Result.propTypes = {
  quizResult: PropTypes.string.isRequired,
  questionsNumber: PropTypes.number.isRequired,
};

export default Result;
