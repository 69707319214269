import React, { useState } from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import { Helmet } from 'react-helmet'

export default function SaoolTools() {
 
  // Google Form Variables
  const [state, setState] = React.useState({link: "",category: 0});

  // Instagram Image Variable
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [resultImage, setResultImage] = useState("");

  const fileChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  
  function googleFormOnSubmit(event) {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        link: state.link,
        category: state.category,
      }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/quizzes/googleform`, requestOptions)
      .then((response) => response.json())
      .then((data) => alert("The quiz is added successfully ya 7elo ;D"));
  }

  function instagramImageOnSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("files", selectedFile);

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    fetch("https://saool-dev.xyz/quizzes/createsocialimage", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        //alert(`رابط الصورة المعدّلة:  https://saool-dev.xyz${data.path}`);
        setResultImage(`https://saool-dev.xyz${data.path}`);
        console.log("shitty stuff", data);
      })
      .catch((error) => console.log("error", error));
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  return (
      <div className="container">
        <Helmet>
          <title>أدوات - سؤول</title>
          <meta name="description" content="أدوات - سؤول" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://saool.io/" />
          <meta property="og:title" content="أدوات - سؤول" />
          <meta property="og:description" content="أدوات - سؤول" />
          <meta property="og:image" content="https://saool.io/assets/images/meta.png"/>
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://saool.io/" />
          <meta property="twitter:title" content="أدوات - سؤول" />
          <meta property="twitter:description" content="أدوات - سؤول" />
          <meta property="twitter:image" content="https://saool.io/assets/images/meta.png"/>
          <meta name="title" content="أدوات - سؤول" />
        </Helmet>

        <h1>أدوات</h1>
        <CSSTransitionGroup
          className="card shadow-sm border-0 rounded mb-0"
          component="div"
          transitionName="fade"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={500}
          transitionAppear
          transitionAppearTimeout={300}>
          <div className={"mb-3 mt-3 ml-3 mr-3"}>
            <h2>إضافة اختبار عن طريق Google Forms</h2>
            <form onSubmit={googleFormOnSubmit}>
              <div>
                <label>رابط الاختبار:</label>
              </div>
              <div>
                <input
                  value={state.link}
                  className="form-control"
                  type="text"
                  name="link"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>الصنف:</label>
              </div>
              <div>
                <input
                  value={state.category}
                  className="form-control"
                  type="number"
                  name="category"
                  onChange={handleChange}
                />
              </div>
              <input className="btn btn-primary" type="submit" value="إضافة" />
            </form>

            <br />
            <hr />
            <br />
            <h2>فريم انستقرام</h2>
            <div>
              <input type="file" name="file" onChange={fileChangeHandler} />
              {isFilePicked ? (
                <div>
                  <p>اسم الملف: {selectedFile.name}</p>
                  <p>صيغة الملف: {selectedFile.type}</p>
                  <p>حجم الملف: {selectedFile.size}</p>
                </div>
              ) : (
                <p>اختر الملف الذي تريد أن تضيف إليه الفريم الخاص بسؤول</p>
              )}

              {resultImage ? (
                <>
                  <p>
                    <a href={resultImage}>رابط التحميل للملف المعدّل!</a>
                  </p>
                </>
              ) : (
                <div></div>
              )}

              <div>
                <button
                  className="btn btn-primary"
                  onClick={instagramImageOnSubmit}>
                  رفع الملف
                </button>
              </div>
            </div>
          </div>
        </CSSTransitionGroup>
      </div>
  );
}
