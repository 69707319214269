import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

export default function RandomQuiz(props) {
  var [quiz, setQuiz] = useState();
  var [isLoading, setIsLoading] = useState(false);

  function randomHandle() {
    setIsLoading(true);
    var response;
    axios({
      url: `${process.env.REACT_APP_API_URL}/graphql`,
      method: "post",
      data: {
        query: `query {
              quizzes{
                  id
                  type
                  title
                  category{
                    name
                    id
                  }
                  image{
                      url
                  }
              }
          }`,
      },
    }).then((serverResponse) => {
      response = serverResponse.data.data.quizzes;
      var quiz = response[Math.floor(Math.random() * response.length)];
      setQuiz(quiz);
    });
  }
  if (quiz) {
    return (
      <Redirect to={`/quiz/${quiz.id}/${quiz.title.replace(/ /g, "-")}`} />
    );
  }
  return (
    <div className="w-100">
      <a
        href="#"
        title="random Quiz"
        className="curser-pointer"
        onClick={() => randomHandle()}>
        <div
          className="container"
          style={{ position: "relative", textAlign: "center" }}>
          <img
            className="w-100 scale"
            style={{ maxHeight: "200px" }}
            src="/assets/images/randomButton.svg"
            alt="اختبار عشوائي"
          />
          {!isLoading ? (
            <div>
              <div
                style={{
                  position: "absolute",
                  top: "55%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontWeight: "bold",
                }}>
                {" "}
                اضغط للكويز العشوائي
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "70%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}>
                {" "}
                أثبت لهم إنك أبو العريف{" "}
                <span aria-label="emoji" role="img">
                  🤓
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  position: "absolute",
                  top: "55%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "10px",
                  fontWeight: "bold",
                }}>
                {" "}
                ثواني بس ندوّر لك على شيء كويس{" "}
                <span aria-label="emoji" role="img">
                  🙄
                </span>
              </div>
              <img
                style={{
                  position: "absolute",
                  top: "70%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "12px",
                  fontWeight: "bold",
                  height: "35px",
                }}
                src="assets/images/spinner-1.svg"
                alt="loader"
              />
            </div>
          )}
        </div>
      </a>
    </div>
  );
}
