import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

function ContactForm(props) {
  return (
    <div className="container">
      <Helmet>
        <title>تواصل معنا - سؤول</title>
        <meta name="description" content="تواصل معنا" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saool.io/" />
        <meta property="og:title" content="تواصل معنا - سؤول" />
        <meta property="og:description" content="تواصل معنا" />
        <meta
          property="og:image"
          content="https://saool.io/assets/images/meta.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://saool.io/" />
        <meta property="twitter:title" content="تواصل معنا - سؤول" />
        <meta property="twitter:description" content="تواصل معنا" />
        <meta
          property="twitter:image"
          content="https://saool.io/assets/images/meta.png"></meta>
        <meta name="title" content="تواصل معنا - سؤول" />
      </Helmet>

      <h1>تواصل معنا</h1>
      <CSSTransitionGroup
        className="card shadow-sm border-0 rounded mb-0"
        component="div"
        transitionName="fade"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={500}
        transitionAppear
        transitionAppearTimeout={300}>
        <div className={"mb-3 mt-3 ml-3 mr-3"}>
          <form
            action="https://getform.io/f/0299cf47-aa8b-4516-ab60-ef20fd27e643"
            method="POST">
            <div>
              <label>الاسم</label>
            </div>
            <div>
              <input className="form-control" type="text" name="name" />
            </div>
            <hr />
            <div>
              <label>البريد الإلكتروني</label>
            </div>
            <div>
              <input className="form-control" type="email" name="email" />
            </div>
            <hr />
            <div>
              <label>الرسالة</label>
            </div>
            <div>
              <textarea className="form-control" type="text" name="message" />
            </div>
            <hr />
            <input type="submit" value="إرسال" />
          </form>
        </div>
      </CSSTransitionGroup>
    </div>
  );
}

export default withRouter(ContactForm);
