import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group'
import { Helmet } from 'react-helmet'

export default function NotFoundPage() {

    return (
        <div className="container">
            <Helmet>
                <title>حدث خطأ 404 - سؤول</title>
                <meta name="description" content="حدث خطأ - سؤول" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://saool.io/" />
                <meta property="og:title" content="حدث خطأ - سؤول" />
                <meta property="og:description" content="حدث خطأ - سؤزل" />
                <meta property="og:image" content="https://saool.io/assets/images/meta.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://saool.io/" />
                <meta property="twitter:title" content="حدث خطأ - سؤول" />
                <meta property="twitter:description" content="حدث خطأ ما" />
                <meta property="twitter:image" content="https://saool.io/assets/images/meta.png" />
                <meta name="title" content="حدث خطأ - سؤول" />
            </Helmet>
            <CSSTransitionGroup
                className="card shadow-sm border-0 rounded mb-0"
                component="div"
                transitionName="fade"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={500}
                transitionAppear
                transitionAppearTimeout={300}>
                <div className={'mb-3 mt-3 ml-3 mr-3 text-center'}>
                    <img src="/assets/images/404.svg" width="200" className="img-fluid text-center mx-auto mb-3" alt="404" />
                    <h1 className="text-center font-weight-bold">الصفحة غير موجودة</h1>
                    <p className="text-dark text-center" style={{color:'#7E7E7E'}}>شاكرين لك حماسك عزيزي اللاعب لكن الصفحة المطلوبة بعدين  بنسويها مو الآن</p>
                    <button class="btn btn-outline-primary" style={{ textAlign: "center" }}>
                        <Link to="/">رجعني للصفحة الرئيسية</Link>
                    </button>
                </div>
            </CSSTransitionGroup>
        </div>
    )
}