import React from 'react'
import PropTypes from 'prop-types'
import Question from '../components/Question'

import AnswerOption from '../components/AnswerOption'
import Skeleton from 'react-loading-skeleton';
function Quiz(props) {
  function renderAnswerOptions(key) {
    return (
      <AnswerOption
        key={key.id}
        id={key.id}
        answerContent={key.text}
        answerType={key.type}
        answer={props.answer}
        quizType={props.type}
        isCorrect={key.is_correct}
        questionId={props.questionId}
        onAnswerSelected={props.onAnswerSelected}
        image={props.image}
      />
    )
  }

  return (
    <div className="container  overflow-hidden">
      <div className="row justify-content-center flex-md-row-reverse">
      {props.question && props.question.image && props.question.image.length > 0 &&
       <div className="col-lg-4 col-md-6">
            <img className="w-100" src={process.env.REACT_APP_API_URL + props.question.image[0].url} style={{ borderRadius: "13px" }} alt={props.quizTitle} />
          </div>}
        <div className="col-lg-4 col-md-6">
      {props.question &&
        <div
          className="card shadow-sm border-0 rounded mb-3"
          component="div"
          transitionName=""
        >
          
        </div>
      }
      <div
        className="card shadow-sm border-0 p-4 rounded mb-3"
        component="div"
        transitionName="">
        <div key={props.questionId}>
          <Question content={props.question}  questionId={props.questionId} questionTotal={props.questionTotal} />
          <hr />
          <ul className="answerOptions mb-0">
            {props.answerOptions.map(id => renderAnswerOptions(id))}
          </ul>

        </div>
      </div>
      </div>
      </div>
    </div>
  )

}

export function QuizLoading(props) {
  return (
    <div className="container  overflow-hidden">
            <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
      <div
        className="card shadow-sm border-0 p-4 rounded mb-3"
        component="div"
        transitionName="">
        <div>
          <Skeleton />
          <hr />
          <ul className="answerOptions mb-0">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </ul>
          <Skeleton />
        </div>
      </div>
      </div>
      </div>
    </div>
  )
}

Quiz.propTypes = {
  answerOptions: PropTypes.array.isRequired,
  question: PropTypes.object.isRequired,
  questionId: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
}

export default Quiz
