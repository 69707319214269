import React, { useState } from 'react'
import PropTypes from 'prop-types'

function AnswerOption(props) {

  const [answerStatus, setAnswerStatus] = useState('')
  function submitAnswerHandle(value) {
    if (value) {
      setAnswerStatus("animate__animated animated__pulse correct-answer")
    }
    else {
      setAnswerStatus("animate__animated animated__pulse wrong-answer")
    }
  }

  return (
    <li className={"answerOption rounded " + answerStatus}>
      {props.quizType === 'numerical' &&
        <div>
          <input
            type="radio"
            className={"radioCustomButton"}
            name="radioGroup"
            checked={props.id === props.answer}
            id={props.id}
            key={props.id}
            value={props.id}
            disabled={props.answer}
            onClick={() => submitAnswerHandle(props.isCorrect)}
            onChange={props.onAnswerSelected}
          />
          <label className={"radioCustomLabel "} htmlFor={props.id} style={{fontSize:'19px'}} >
            {props.answerContent}
          </label>
        </div>
      }
      {props.quizType === 'categorical' &&
        <div>
          <input
            type="radio"
            className={"radioCustomButton"}
            name="radioGroup"
            checked={props.answerType === props.answer}
            id={props.answerType}
            key={props.answerType}
            value={props.answerType}
            disabled={props.answer}
            onClick={() => submitAnswerHandle(props.isCorrect)}
            onChange={props.onAnswerSelected}
          />
          <label className={"radioCustomLabel "} htmlFor={props.answerType} style={{fontSize:'19px'}}  >
            {props.answerContent}
          </label>
        </div>
      }

    </li>
  )
}

AnswerOption.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  isCorrect: PropTypes.bool.isRequired
}

export default AnswerOption
