import * as React from 'react'
import {
  Switch,
  Route
} from 'react-router-dom'

import Home from './pages/Home'
import About from './pages/About'
import ContactForm from './pages/ContactForm'
import GetQuiz from './pages/QuizPage'
import QuizInfo from './pages/QuizInfoPage'
import Privacy from './pages/Privacy'
import Saool from './pages/Saool'
import NotFoundPage from './pages/NotFoundPage'
import SaoolTools from './pages/SaoolTools'

export default function Routes () {
  return (
    <Switch>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/privacy">
        <Privacy />
      </Route>
      <Route path="/contactus">
        <ContactForm />
      </Route>
      <Route path="/saool">
        <Saool />
      </Route>
      <Route path="/saooltools">
        <SaoolTools />
      </Route>
      <Route exact path="/Categories/:category/:quiz/start">
        <GetQuiz />
      </Route>
      <Route exact path="/Categories/:category/:quiz">
        <QuizInfo />
      </Route>
      <Route path="/quiz/:quizID/:quiz?">
        <QuizInfo />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}

