import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { Helmet } from "react-helmet";

export default function Privacy() {
  return (
    <div>
      <div className="container">
        <Helmet>
          <title>سياسة الخصوصية - سؤول</title>
          <meta name="description" content="سياسة الخصوصية - سؤول" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://saool.io/" />
          <meta property="og:title" content="سياسة الخصوصية - سؤول" />
          <meta property="og:description" content="سياسة الخصوصية - سؤول" />
          <meta
            property="og:image"
            content="https://saool.io/assets/images/meta.png"
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://saool.io/" />
          <meta property="twitter:title" content="سياسة الخصوصية - سؤول" />
          <meta
            property="twitter:description"
            content="سياسة الخصوصية - سؤول"
          />
          <meta
            property="twitter:image"
            content="https://saool.io/assets/images/meta.png"
          />
          <meta name="title" content="سياسة الخصوصية - سؤول" />
        </Helmet>
        <h1>سياسة الخصوصية</h1>
        <CSSTransitionGroup
          className="card shadow-sm border-0 rounded mb-0"
          component="div"
          transitionName="fade"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={500}
          transitionAppear
          transitionAppearTimeout={300}>
          <div className={"mb-3 mt-3 ml-3 mr-3"}>
            <p>
              <strong>أ &ndash; مقدمة</strong>
            </p>
            <p>
              <strong>
                إننا نهتم بشكل كبير بخصوصية زوار موقعنا، ونتعهد بحمايتها. تشرح
                هذه السياسة كيفية التصرف في معلوماتك الشخصية.
              </strong>
            </p>
            <p>
              <strong>
                بموافقتك على استخدامنا لملفات تعريف الارتباط وفقًا لبنود هذه
                السياسة عند زيارتك لموقعنا الإلكتروني لأول مرة فإنك تسمح لنا
                باستخدام ملفات تعريف الارتباط في كل مرة تزور فيها موقعنا.
              </strong>
            </p>
            <p>
              <strong>ب. حقوق التأليف</strong>
            </p>
            <p>قام فريق سؤول بالعمل على تنفيذ هذه المسودة من سياسة الخصوصية.</p>
            <p>
              <strong>ج. جمع المعلومات الشخصية</strong>
            </p>
            <p>
              <strong>
                قد يتم جمع وتخزين واستخدام المعلومات الشخصية التالية:
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  معلومات حول جهاز الكمبيوتر الخاص بك بما في ذلك عنوان بروتوكول
                  الإنترنت الخاص بك، والموقع الجغرافي، ونوع المتصفح والإصدار،
                  ونظام التشغيل.
                </strong>
              </li>
              <li>
                <strong>
                  معلومات حول زياراتك لهذا الموقع واستخدامه بما في ذلك مصدر
                  الإحالة، ومدة الزيارة، وما تشاهده على الصفحة، ومسارات التصفح
                  في الموقع.
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات التي تدخلها عند التسجيل في موقعنا الإلكتروني، مثل
                  عنوان بريدك الإلكتروني.
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات التي تدخلها عند إنشاء ملف تعريف على موقعنا &ndash;
                  على سبيل المثال، اسمك، صور ملفك الشخصي، الجنس، تاريخ الميلاد،
                  الحالة الاجتماعية، الاهتمامات والهوايات، الحالة التعليمية،
                  وتفاصيل الوظيفة.
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات، مثل اسمك وعنوان بريدك الإلكتروني، التي تدخلها من
                  أجل إعداد اشتراكات في رسائل البريد الإلكتروني و / أو الرسائل
                  الإخبارية لدينا ؛
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات التي تدخلها أثناء استخدام الخدمات على موقعنا.
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات التي يتم إنشاؤها أثناء استخدام موقعنا الإلكتروني،
                  بما في ذلك مواعيد وتكرار استخدامك للموقع والظروف التي تستخدم
                  فيها الموقع.
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات المتعلقة بأي شيء تشتريه، أو الخدمات التي تستخدمها،
                  أو المعاملات التي تجريها من خلال موقعنا الإلكتروني، والتي تشمل
                  اسمك وعنوانك ورقم هاتفك وعنوان بريدك الإلكتروني وتفاصيل بطاقة
                  الائتمان.
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات التي تنشرها على موقعنا الإلكتروني بغرض مشاركتها على
                  الإنترنت، والتي تشمل اسم المستخدم الخاص بك وصور ملفك الشخصي
                  ومحتوى مشاركاتك.
                </strong>
              </li>
              <li>
                <strong>
                  المعلومات الواردة في أي مراسلات ترسلها إلينا عبر البريد
                  الإلكتروني أو عبر موقعنا الإلكتروني، بما في ذلك المحتوى
                  المشترك والبيانات الوصفية.
                </strong>
              </li>
              <li>
                <strong>أي معلومات شخصية أخرى ترسلها إلينا.</strong>
              </li>
              <li>
                <strong>
                  قبل أن تفصح لنا عن المعلومات الشخصية الخاصة بشخص آخر، ينبغي
                  عليك الحصول على موافقة هذا الشخص على كل من الكشف عن هذه
                  المعلومات الشخصية ومعالجتها وفقًا لهذه السياسة.
                </strong>
              </li>
            </ul>

            <p>
              <strong>د. استخدام معلوماتك الشخصية</strong>
            </p>
            <p>
              <strong>
                تستخدم المعلومات الشخصية المقدمة إلينا عبر موقعنا الإلكتروني في
                الأغراض الموضحة في هذه السياسة أو على الصفحات ذات الصلة من
                الموقع. قد نستخدم معلوماتك الشخصية في الأغراض التالية:
              </strong>
            </p>
            <ul>
              <li>
                <strong>إدارة موقعنا وأعمالنا على شبكة الإنترنت.</strong>
              </li>
              <li>
                <strong>
                  تخصيص موقعنا على شبكة الإنترنت ليكون أكثر ملاءمة بالنسبة لك.
                </strong>
              </li>
              <li>
                <strong>تمكينك من استخدام الخدمات المتاحة على موقعنا.</strong>
              </li>
              <li>
                <strong>
                  توفير الخدمات المشتراة من خلال موقعنا على شبكة الإنترنت.
                </strong>
              </li>
              <li>
                <strong>
                  إرسال البيانات والفواتير وإشعارات الدفع لك، وتحصيل المدفوعات
                  منك.
                </strong>
              </li>
              <li>
                <strong>إرسال رسائل تجارية غير تسويقية إليك.</strong>
              </li>
              <li>
                <strong>
                  إرسال إشعارات عبر البريد الإلكتروني التي قمت بطلب إرسالها على
                  وجه التحديد إليك.
                </strong>
              </li>
              <li>
                <strong>
                  إرسال نشرة البريد الإلكتروني إليك، إذا كنت قد طلبت ذلك (يمكنك
                  إبلاغنا في أي وقت إذا لم تعد تحتاج إلى النشرة الإخبارية).
                </strong>
              </li>
              <li>
                <strong>
                  إرسال المراسلات التسويقية المتعلقة بأعمالنا أو أعمال الجهات
                  الخارجية التي تم اختيارها بعناية والتي نعتقد أنها قد تهمك، عن
                  طريق البريد أو، إذا كنت قد وافقت على ذلك بالتحديد، عن طريق
                  البريد الإلكتروني أو أي تقنية مشابهة (يمكنك إبلاغنا في أي وقت
                  إذا لم تعد تحتاج إلى هذه المراسلات التسويقية).
                </strong>
              </li>
              <li>
                <strong>
                  تزويد الأطراف الثالثة بمعلومات إحصائية عن مستخدمينا (غير أن
                  هذه الأطراف الثالثة لن تتمكن من تحديد المستخدمين لتلك
                  المعلومات بحد ذاتهم).
                </strong>
              </li>
              <li>
                <strong>
                  التعامل مع الاستفسارات والشكاوى التي تقدمها أنت أو تقدم لنا
                  بشأنك على موقعنا.
                </strong>
              </li>
              <li>
                <strong>حماية موقعنا من الاحتيال والحفاظ على أمنه.</strong>
              </li>
              <li>
                <strong>
                  التحقق من الامتثال للشروط والأحكام التي تحكم استخدام موقعنا
                  الإلكتروني (بما في ذلك مراقبة الرسائل الخاصة المرسلة عبر خدمة
                  الرسائل الخاصة بموقعنا).
                </strong>
              </li>
              <li>
                <strong>استخدامات أخرى.</strong>
              </li>
              <li>
                <strong>
                  إذا قمت بتقديم معلومات شخصية للنشر على موقعنا، فسنقوم بنشر هذه
                  المعلومات واستخدامها بأي شكل آخر وفقًا للترخيص الذي تمنحه لنا.
                </strong>
              </li>
              <li>
                <strong>
                  يمكن استخدام إعدادات الخصوصية الخاصة بك للحد من نشر معلوماتك
                  على موقعنا الإلكتروني ويمكن تعديلها باستخدام عناصر التحكم في
                  الخصوصية على الموقع.
                </strong>
              </li>
              <li>
                <strong>
                  لن نزود أي طرف ثالث، دون موافقتك الصريحة، بمعلوماتك الشخصية
                  لأغراض التسويق المباشر لأي طرف آخر.
                </strong>
              </li>
            </ul>

            <p>
              <strong>هـ &ndash; الكشف عن المعلومات الشخصية</strong>
            </p>
            <p>
              <strong>
                يمكن أن نقوم بالكشف عن معلوماتك الشخصية لأي من موظفينا أو
                مسؤولينا أو شركات التأمين أو المستشارين المحترفين أو الوكلاء أو
                الموردين أو المقاولين من الباطن التابعين لنا القدر الضروري بشكل
                معقول للأغراض المنصوص عليها في هذه السياسة.
              </strong>
            </p>

            <p>
              <strong>
                قد نقوم بالإفصاح عن معلوماتك الشخصية لأي عضو في مجموعة شركاتنا
                (وهذا يعني الشركات التابعة لنا، والشركة القابضة الأصلية وجميع
                فروعها) بالقدر الضروري بشكل معقول للأغراض المنصوص عليها في هذه
                السياسة.
              </strong>
            </p>

            <p>
              <strong>قد نكشف عن معلوماتك الشخصية:</strong>
            </p>

            <ul>
              <li>
                <strong>القدر الذي يطلب منا القيام به بموجب القانون.</strong>
              </li>
              <li>
                <strong>فيما يتعلق بأي إجراءات قانونية جارية أو محتملة.</strong>
              </li>
              <li>
                <strong>
                  من أجل إنشاء أو ممارسة أو الدفاع عن حقوقنا القانونية (بما في
                  ذلك توفير معلومات للآخرين لأغراض منع الاحتيال والحد من مخاطر
                  الائتمان).
                </strong>
              </li>
              <li>
                <strong>
                  إلى المشتري (أو المشتري المحتمل) لأي عمل أو أصل نبيعه (أو ننوي
                  ذلك).
                </strong>
              </li>
              <li>
                <strong>
                  أي شخص نعتقد على نحو معقول أنه قد يتقدم بطلب إلى محكمة أو سلطة
                  مختصة أخرىبطلب الإفصاح عن تلك المعلومات الشخصية، وفقًا
                  لتقديرنا المعقول، ويكون من المحتمل بشكل معقول أن تأمر هذه
                  المحكمة أو السلطة بالكشف عن تلك المعلومات الشخصية، باستثناء ما
                  هو منصوص عليه في هذه السياسة، فإننا لن نقدم معلوماتك الشخصية
                  إلى أطراف ثالثة.
                </strong>
              </li>
            </ul>
            <p>
              <strong>و &ndash; النقل الدولي للبيانات</strong>
            </p>
            <ul>
              <li>
                <strong>
                  قد يتم تخزين المعلومات التي نجمعها أو معالجتها أو نقلها بين أي
                  من البلدان التي نعمل فيها لتمكيننا من استخدام المعلومات وفقًا
                  لهذه السياسة.
                </strong>
              </li>
              <li>
                <strong>
                  قد يتم نقل المعلومات التي نجمعها إلى البلدان التالية والتي لا
                  تخضع لقوانين حماية البيانات المكافئة لتلك المعمول بها في
                  المنطقة الاقتصادية الأوروبية: وهذه الدول هي الولايات المتحدة
                  الأمريكية، روسيا، اليابان، الصين، والهند.
                </strong>
              </li>
              <li>
                <strong>
                  لا نستطيع منع استخدام الآخرين أو إساءة استخدامهم للمعلومات
                  الشخصية التي تنشرها على موقعنا الإلكتروني أو ترسلها للنشر على
                  موقعنا الإلكتروني والمتاحة عبر الإنترنت في جميع أنحاء العالم.
                </strong>
              </li>
              <li>
                <strong>
                  أنت توافق صراحة على عمليات نقل المعلومات الشخصية الموضحة في
                  هذا القسم &ldquo;و&rdquo;.
                </strong>
              </li>
            </ul>
            <p>
              <strong>ز. الاحتفاظ بالمعلومات الشخصية</strong>
            </p>
            <ul>
              <li>
                <strong>
                  يحدد هذا القسم &ldquo;ز&rdquo; السياسات والإجراءات التي نتبعها
                  للاحتفاظ بالبيانات، والتي صممت للمساعدة في ضمان امتثالنا
                  لالتزاماتنا القانونية المتعلقة بالاحتفاظ بالمعلومات الشخصية
                  وحذفها.
                </strong>
              </li>
              <li>
                <strong>
                  لا يجوز الاحتفاظ بالمعلومات الشخصية التي نقوم بمعالجتها لأي
                  غرض أو أغراض لفترة أطول مما هو ضروري لهذا الغرض أو لتلك
                  الأغراض.
                </strong>
              </li>
              <li>
                <strong>
                  . دون الإخلال بالبند ز-2، فإننا عادة ما نقوم بحذف البيانات
                  الشخصية التي تندرج ضمن الفئات المبينة أدناه في التاريخ / الوقت
                  المبين أدناه:
                </strong>
                <ul>
                  <li>
                    <strong>نوع البيانات الشخصية تحذف في كل </strong>
                    <strong>XXXX</strong>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  بصرف النظر عن الأحكام الأخرى الواردة في هذا القسم ز، فإننا
                  نحتفظ بالوثائق (بما في ذلك المستندات الإلكترونية) التي تحتوي
                  على بيانات شخصية:
                </strong>
                <ul>
                  <li>
                    <strong>بالقدر المطلوب بموجب القانون.</strong>
                  </li>
                  <li>
                    <strong>
                      إذا كنا نعتقد أن هذه المستندات قد تكون ذات صلة بأي إجراءات
                      قانونية جارية أو محتملة.
                    </strong>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>ح. أمن معلوماتك الشخصية</strong>
            </p>
            <ul>
              <li>
                <strong>
                  أمن معلوماتك الشخصية نتعهد باتخاذ الاحتياطات التقنية
                  والتنظيمية المعقولة لمنع فقدان معلوماتك الشخصية أو إساءة
                  استخدامها أو تغييرها.
                </strong>
              </li>
              <li>
                <strong>
                  نتعهد بتخزين جميع المعلومات الشخصية التي تقدمها على خوادمنا
                  الآمنة (المحمية بكلمة مرور وجدار الحماية).
                </strong>
              </li>
              <li>
                <strong>
                  جميع المعاملات المالية الإلكترونية التي يتم القيام بها من خلال
                  موقعنا الإلكتروني محمية بتقنية التشفير.
                </strong>
              </li>
              <li>
                <strong>
                  تقرّ بموافقتك على هذه السياسة بمعرفتك بأن نقل المعلومات عبر
                  الإنترنت غير آمن بطبيعته، ولا يمكننا ضمان أمن البيانات المرسلة
                  عبر الإنترنت.
                </strong>
              </li>
              <li>
                <strong>
                  أنت مسؤول عن الحفاظ على سرية كلمة المرور التي تستخدمها للوصول
                  إلى موقعنا. لن نطلب منك كلمة المرور الخاصة بك (إلا عند تسجيل
                  الدخول إلى موقعنا).
                </strong>
              </li>
            </ul>
            <p>
              <strong>ط. التعديلات</strong>
            </p>
            <p>
              <strong>
                قد نقوم بتحديث هذه السياسة من وقت لآخر عن طريق نشر نسخة جديدة
                على موقعنا. يجب عليك مراجعة هذه الصفحة من حين لآخر للتأكد من
                فهمك لأي تغييرات تطرأ على هذه السياسة، وقد نخطرك بالتغييرات التي
                تطرأ على هذه السياسة عن طريق البريد الإلكتروني أو من خلال نظام
                المراسلة الخاص على موقعنا أو عبر أي قنوات أخرى.{" "}
              </strong>
            </p>
          </div>
        </CSSTransitionGroup>
      </div>
    </div>
  );
}
