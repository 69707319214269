import React from 'react'
import PropTypes from 'prop-types'
import QuestionCount from '../components/QuestionCount'

function Question(props) {
  return <div>
    {props.content &&
      <div>
        <QuestionCount counter={props.questionId} total={props.questionTotal} />
        <h2 className="font-weight-bold mb-0">{props.content.statement}</h2>
      </div>
    }
  </div>
}

Question.propTypes = {
  content: PropTypes.object.isRequired
}

export default Question
