import React from 'react'
import { Link } from 'react-router-dom'


export default function Footer(props) {
    return (
      <footer className="page-footer font-small blue pt-4 bg-white shadow shadow-sm">
        <div className="container text-center text-md-left bg-white">
          <div className="row">
            <div className="col-md-12 mb-md-0 mb-3">
              <ul className="list-unstyled pr-0">
                <li>
                  <Link title="عن سؤول" className="nav-link text-dark font-weight-bold" to="/saool">عن سؤول</Link>
                </li>
                <li>
                  <Link title="تعريف المنتج" className="nav-link text-dark font-weight-bold" to="/about">تعريف المنتج</Link>
                </li>
                <li>
                  <Link title="سياسة الخصوصية" className="nav-link text-dark font-weight-bold" to="/privacy">سياسة الخصوصية</Link>
                </li>
                <li>
                  <span className="nav-link text-dark font-weight-bold">تابعنا على كل من</span>
                </li>
                <li>
                  <div className="nav-link text-dark font-weight-bold">
                    <a href="https://twitter.com/SaoolTrivia"><img src="/assets/images/twitter.svg" href="https://twitter.com/SaoolTrivia" alt="Twitter Link" style={{marginLeft: "10px"}}/></a>
                    <a href="https://www.facebook.com/SaoolTrivia"><img src="/assets/images/facebook.svg"  alt="Facebook Link" style={{marginLeft: "10px"}}/></a>
                    <a href="https://instagram.com/SaoolTrivia"><img src="/assets/images/instagram.svg"  alt="Instagram Link" style={{marginLeft: "10px"}}/></a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-12 mt-md-0 my-3">
              <h2 className="text-uppercase text-center font-weight-bold mb-3">ودك تتعاون معنا؟ كلمنا من هنا!</h2>
              <a title="تواصل معنا" href="https://twitter.com/SaoolTrivia" className="btn btn-block btn-primary btn-lg rounded">تواصل معنا</a>
            </div>
            <hr/>
            <div className="footer-copyright border-top py-4 text-center col-md-12">
              <div className="col-md-12">
                <p>
                        جميع الحقوق محفوظة 2021©
                </p>
              </div>
              <div className="col-md-6">
              <Link title="الأدوات" className="nav-link" to="/saooltools"></Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}
